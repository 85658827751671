import React, { useEffect } from "react"

export default ({ cancelHandler, deleteHandler, lang_config, dir }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"

    return () => (document.body.style.overflowY = "scroll")
  })

  return (
    <div id="modal" onClick={cancelHandler} style={{ top: window.pageYOffset }}>
      <div
        id="modal-panel"
        onClick={e => e.stopPropagation()}
        className={`text-center ${dir}`}
      >
        <h5>{lang_config.message}</h5>
        <h6>{lang_config.disclaimer}</h6>
        <div id="btn-holder">
          <button
            className="btn btn-sm gray simple-hover"
            onClick={cancelHandler}
          >
            {lang_config.button_cancel}
          </button>
          <button
            className="btn btn-sm red simple-hover"
            onClick={deleteHandler}
          >
            {lang_config.button_delete}
          </button>
        </div>
      </div>
    </div>
  )
}
