import React, { useState, useEffect } from "react"

export default ({
  children,
  sectionName,
  className,
  open,
  dir,
  closedByDefault,
}) => {
  const [visible, setVisible] = useState(true)

  const openClose = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    if (closedByDefault) {
      setVisible(open || false)
    }
  }, [open])

  return (
    <div className={`row ${className}`}>
      <div className="col-xs-12 section-header no-select">
        <h4 onClick={openClose}>
          {sectionName}
          <span className="no-print plus-minus">{visible ? "-" : "+"}</span>
        </h4>
      </div>
      {visible ? (
        <div className="col-xs-12 section-body">{children}</div>
      ) : null}
    </div>
  )
}
