import React, { useState, useRef } from "react"
import axios from "axios"
import { GoogleLogin, GoogleLogout } from "react-google-login"
import Layout from "./layout"
import Loading from "./loading"
import Recorder from "./recorder"
import Modal from "./modal"
import Section from "./section"
import Profile from "./profile"
import InteractiveChart from "./interactive-chart"

const baseApiEndpoint =
  "https://n3kcdu9wue.execute-api.ca-central-1.amazonaws.com/prod"
// "https://n3kcdu9wue.execute-api.ca-central-1.amazonaws.com/dev"

export default ({ lang_config }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [username, setUsername] = useState("")
  const [loading, setLoading] = useState(true)
  const [loadingHistory, setLoadingHistory] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [profileChartData, setProfileChartData] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const profileExists = useRef(false)
  const [showProfile, setShowProfile] = useState(false)
  const [profileInfo, setProfileInfo] = useState(null)

  const gid = useRef(null)

  const onVoiceResult = (data, blobUrl) => {
    setProfileChartData([...profileChartData, data])
  }

  const logout = response => {
    setLoggedIn(false)
    profileExists.current = false
    setProfileInfo(null)
    setProfileChartData([])
  }

  const loadHistory = email => {
    setLoadingHistory(true)
    // email = "hamid.vatanparast@gmail.com"
    axios
      .get(`${baseApiEndpoint}/history?username=${email}&t=Data`, {
        headers: {
          "g-id": gid.current,
          em: email,
        },
      })
      .then(function (response) {
        response = JSON.parse(response.data)
        setProfileChartData(response)
        setLoadingHistory(false)
      })
      .catch(function (error) {
        alert("Something went wrong!")
      })
      .then(function () {})
  }

  const cancelModal = () => {
    setModalVisible(false)
  }

  const deleteConfirmed = () => {
    setModalVisible(false)
    setLoadingDelete(true)

    axios
      .delete(`${baseApiEndpoint}/history`, {
        headers: {
          "g-id": gid.current,
          em: userEmail,
        },
        data: {
          u: userEmail,
        },
      })
      .then(response => {
        clearEverything()
      })
      .catch(error => console.log(error))
      .then(() => {
        setLoadingDelete(false)
      })
  }

  const clearEverything = () => {
    setProfileChartData([])
  }

  const editProfile = () => {
    setShowProfile(true)
  }

  const checkProfile = email => {
    axios
      .get(baseApiEndpoint + `/profile?username=${email}`, {
        headers: {
          "g-id": gid.current,
          em: email,
        },
      })
      .then(res => {
        setLoading(false)
        const response = JSON.parse(res.data)
        if (response.length > 0) {
          profileExists.current = true
          const profile = response[0]
          setProfileInfo(profile)
          loadHistory(email)
          if (!profile.not_tested && !profile.positive && !profile.negative) {
            setShowProfile(true)
          } else {
            setShowProfile(false)
          }
        } else {
          setShowProfile(true)
        }
      })
      .catch(res => alert("Something went wrong!"))
  }

  const successIn = response => {
    setLoggedIn(true)
    setUsername(response.profileObj.email)
    const email = response.profileObj.email
    setUserEmail(email)
    gid.current = response.tokenId
    checkProfile(email)
    console.log("success")
  }

  const failedIn = response => {
    setLoggedIn(false)
    console.log("failed")
  }

  const deleteEverything = () => {
    setModalVisible(true)
  }

  const saveProfileCallback = profileObj => {
    if (profileObj === null) {
      setShowProfile(false)
      return
    }
    if (profileExists.current) {
      axios
        .put(
          baseApiEndpoint + "/profile",
          JSON.stringify({
            username,
            ...profileObj,
          }),
          {
            headers: {
              "Content-type": "application/json",
              "g-id": gid.current,
              em: userEmail,
            },
          }
        )
        .then(res => {
          setShowProfile(false)
          setProfileInfo(JSON.parse(res.data))
        })
        .catch(res => alert("Something went wrong!"))
    } else {
      axios
        .post(
          baseApiEndpoint + "/profile",
          JSON.stringify({
            username,
            ...profileObj,
          }),
          {
            headers: {
              "Content-type": "application/json",
              "g-id": gid.current,
              em: userEmail,
            },
          }
        )
        .then(res => {
          setShowProfile(false)
          setProfileInfo(JSON.parse(res.data))
          profileExists.current = true
        })
        .catch(res => alert("Something went wrong!"))
    }
  }

  const Alarm = ({ prevEntropy, currentEntropy }) => {
    return (
      <p className="alarm">
        {currentEntropy > prevEntropy + 0.05
          ? lang_config.trend.up
          : currentEntropy < prevEntropy - 0.05
          ? lang_config.trend.down
          : lang_config.trend.stable}
      </p>
    )
  }

  const enoughTimeHasPassed = prev => {
    if (
      profileChartData.length > 1 &&
      Date.now() >
        Date.parse(profileChartData[profileChartData.length - 2].date) + 7200000
    ) {
      return true
    }
  }

  return loggedIn ? (
    <>
      {modalVisible ? (
        <Modal
          username={userEmail}
          cancelHandler={cancelModal}
          deleteHandler={deleteConfirmed}
          lang_config={lang_config.delete_dialog}
          dir={lang_config.dir}
        />
      ) : null}
      <Layout
        title="Main"
        dir={lang_config.dir}
        menu={lang_config.menu}
        description="Analyze Your Voice"
      >
        <div className="row" id="username-row">
          <div className="col-xs-8 text-left">
            <h6 className="no-print">
              <strong className="user-email">{username}</strong> (
              <GoogleLogout
                clientId="546695907010-df430tps9kbdhsf1l8g4ad2gfeh81chm.apps.googleusercontent.com"
                render={renderProps => (
                  <span
                    className="logout-btn"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    {lang_config.link_logout}
                  </span>
                )}
                onLogoutSuccess={logout}
              ></GoogleLogout>
              &nbsp;-&nbsp;
              <a
                onClick={editProfile}
                href="#profile-edit"
                className="logout-btn"
              >
                {lang_config.link_edit_profile}
              </a>
              )
            </h6>
          </div>
          <div className="col-xs-4 text-left text-right no-print">
            {/* <button
              className="btn btn-xs btn-simple"
              onClick={() => window.print()}
            >
              {lang_config.button_print}
            </button>
            &nbsp; */}
            <button
              className="btn btn-xs btn-simple danger"
              disabled={loadingDelete}
              onClick={deleteEverything}
            >
              {!loadingDelete
                ? lang_config.button_delete
                : lang_config.button_processing}
            </button>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : !showProfile ? (
          <>
            <Section
              dir={lang_config.dir}
              className="no-print"
              sectionName={lang_config.panel_title_instructions}
              // open={profileChartData.length === 0}
              open={false}
              closedByDefault={true}
            >
              <div
                className="instructions"
                dangerouslySetInnerHTML={{
                  __html: lang_config.instructions + lang_config.privacy_policy,
                }}
              />
            </Section>
            <Section
              dir={lang_config.dir}
              className="no-print"
              sectionName={lang_config.panel_title_recorder}
            >
              <Recorder
                callback={onVoiceResult}
                profileCallback={saveProfileCallback}
                username={userEmail}
                gid={gid.current}
                dir={lang_config.dir}
                noupload={true}
                lang_config={lang_config}
                baseApiEndpoint={baseApiEndpoint}
                lastPoint={
                  profileChartData.length > 0
                    ? profileChartData[profileChartData.length - 1].date
                    : ""
                }
                dataPoints={profileChartData.length}
                profileInfo={profileInfo}
              />
              {/* {profileChartData.length > 1 && enoughTimeHasPassed() ? (
                <Alarm
                  prevEntropy={
                    profileChartData[profileChartData.length - 2].entropy
                  }
                  currentEntropy={
                    profileChartData[profileChartData.length - 1].entropy
                  }
                />
              ) : null} */}
            </Section>

            {/* <Section
              dir={lang_config.dir}
              sectionName={lang_config.panel_title_plot}
            >
              {loadingHistory ? (
                <Loading />
              ) : (
                <div className="row row-no-gutters" id="charts-holder">
                  <div className="col-xs-12" id="chart-holder">
                    <div className="row row-no-gutters">
                      <InteractiveChart
                        lang_config={lang_config}
                        data={profileChartData}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Section> */}
          </>
        ) : (
          <Section sectionName={lang_config.panel_title_profile}>
            <Profile
              privacy_policy={lang_config.privacy_policy}
              lang_config={lang_config.profile}
              callback={saveProfileCallback}
              profileInfo={profileInfo}
            />
          </Section>
        )}
      </Layout>
    </>
  ) : (
    <Layout
      title="Main"
      description="Analyze Your Voice"
      menu={lang_config.menu}
    >
      <div id="google-btn-holder" style={{ visibility: "visible" }}>
        <GoogleLogin
          clientId="546695907010-df430tps9kbdhsf1l8g4ad2gfeh81chm.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={successIn}
          onFailure={failedIn}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
          className="google-login-btn"
          onAutoLoadFinished={() => console.log("finished")}
          onRequest={() => console.log("request")}
        />
      </div>
    </Layout>
  )
}
