import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Recorder from "../lib/recorder"
import checkFileExtention from "../lib/check-file-extension"
import useFileUpload from "../hooks/useFileUpload"
import Questionnaire from "./questionnaire"

export default ({
  callback,
  profileCallback,
  username,
  gid,
  noupload,
  dir,
  dataPoints,
  lang_config,
  lastPoint,
  baseApiEndpoint,
  profileInfo,
}) => {
  const URL = window.URL || window.webkitURL
  let defaultLimit = 30
  const [buttonLabel, setButtonLabel] = useState(
    lang_config.recorder.button_idle
  )
  const [remaining, setRemaining] = useState(defaultLimit)
  const [started, setStarted] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [stream, setStream] = useState(null)
  const [recording, setRecording] = useState(false)
  const [fileFormatError, setFileFormatError] = useState(false)
  const [files, setFiles] = useState([])
  const [questionMode, setQuestionMode] = useState(false)
  const [disableRecord, setDisableRecord] = useState(false)
  const [happy, setHappy] = useState(false)
  const [happyInQuestion, setHappyInQuestion] = useState(false)

  const blobRef = useRef(null)

  let AudioContext = window.AudioContext || window.webkitAudioContext
  let audioContext

  const fileExtension = "wav"

  const aRef = useRef(null)

  const [apiResults, WavFileUploader, setWavUploaderId] = useFileUpload(
    "Calculate",
    "wav",
    `${baseApiEndpoint}/voice`
  )

  useEffect(() => {
    setWavUploaderId(username)
  }, [username])

  useEffect(() => {
    //console.log("from the parent: ", apiResults)
    if (apiResults.length > 0) {
      callback(apiResults, null)
    }
  }, [apiResults])

  useEffect(() => {
    if (started) {
      recorder.record()
      setRecording(true)
    }
  }, [started])

  const questionnaireCallback = ({
    positive,
    not_tested,
    negative,
    showing_symptoms,
    symptoms,
  }) => {
    analyze(positive, negative, not_tested, showing_symptoms, symptoms)
    profileCallback({
      ...profileInfo,
      symptoms,
      positive,
      negative,
      not_tested,
      showing_symptoms,
    })
  }

  useEffect(() => {
    if (
      dataPoints > 0 &&
      lastPoint !== "" &&
      Date.now() < Date.parse(lastPoint) + 86400000
    ) {
      setDisableRecord(true)
    } else {
      if (disableRecord) {
        setDisableRecord(false)
      }
    }
  }, [lastPoint])

  function startRecording() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(stream => {
        setButtonLabel(lang_config.recorder.button_recording)
        audioContext = new AudioContext()
        setStream(stream)
        const input = audioContext.createMediaStreamSource(stream)
        setRecorder(
          new Recorder(input, {
            numChannels: 1,
          })
        )
        setStarted(true)
      })
      .catch(err => {
        alert("Microphone Not Found")
      })
  }

  useEffect(() => {
    let intervalID
    if (started) {
      intervalID = setInterval(() => {
        setRemaining(remaining => remaining - 1)
        //console.log(remaining, recorder);
        if (remaining === 0) {
          setStarted(false)
          stopRecording()
        }
      }, 1000)
    } else {
      clearInterval(intervalID)
    }
    return () => clearInterval(intervalID)
  }, [started, remaining])

  function stopRecording() {
    recorder.stop()
    setButtonLabel(lang_config.recorder.button_processing)
    setRemaining(defaultLimit)

    stream.getAudioTracks()[0].stop()

    recorder.exportWAV(createDownloadLink)
  }

  const ImNotHappy = () => {
    setHappyInQuestion(false)
    // setQuestionMode(false)
    setRecording(false)
    setButtonLabel(lang_config.recorder.button_idle)
  }

  const ImHappy = () => {
    if (dataPoints > 0) {
      setQuestionMode(true)
    } else {
      analyze()
    }
    setHappy(true)
    setHappyInQuestion(false)
  }

  const createDownloadLink = blob => {
    var url = URL.createObjectURL(blob)
    aRef.current.href = url
    aRef.current.download = `${username}-${Date.now()}.wav`
    blobRef.current = blob
    setHappyInQuestion(true)
    // if (dataPoints > 0) {
    //   setQuestionMode(true)
    // } else {
    //   analyze()
    // }
    // if (
    //   dataPoints > 0 &&
    //   lastPoint !== "" &&
    //   // Date.now() > Date.parse(lastPoint) + 72
    //   Date.now() > Date.parse(lastPoint) + 7200000
    // ) {
    //   setQuestionMode(true)
    // } else {
    //   analyze("-")
    // }
  }

  const getCurrentDate = () => {
    var today = new Date()
    const day = String(today.getDate()).padStart(2, "0")
    const month = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    const year = today.getFullYear()
    const hours = String(today.getHours()).padStart(2, "0")
    const minutes = String(today.getMinutes()).padStart(2, "0")
    const seconds = String(today.getSeconds()).padStart(2, "0")

    return `${year}/${month}/${day}-${hours}:${minutes}:${seconds}`
  }

  function analyze(
    positive = profileInfo.positive,
    negative = profileInfo.negative,
    not_tested = profileInfo.not_tested,
    showing_symptoms = profileInfo.showing_symptoms,
    symptoms = profileInfo.symptoms
  ) {
    const data = new FormData()
    data.append("file", blobRef.current)
    data.set("username", username)
    data.set("date", getCurrentDate())
    data.set("positive", positive ? 1 : 0)
    data.set("negative", negative ? 1 : 0)
    data.set("not_tested", not_tested ? 1 : 0)
    data.set("showing_symptoms", showing_symptoms ? 1 : 0)
    data.set("symptoms", JSON.stringify(symptoms))
    // data.set("better", better)
    axios
      .post(`${baseApiEndpoint}/voice`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "g-id": gid,
          em: username,
        },
      })
      .then(res => {
        callback(JSON.parse(res.data), URL.createObjectURL(blobRef.current))
      })
      .catch(error => {
        alert("Something went wrong! Please try again!")
      })
      .then(() => {
        setQuestionMode(false)
        setRecording(false)
        setButtonLabel(lang_config.recorder.button_idle)
      })
  }

  const changeHandler = event => {
    const selectedFiles = event.target.files
    if (selectedFiles.length === 0) {
      return
    }

    setFiles([...selectedFiles])
    for (let file of selectedFiles) {
      if (!checkFileExtention(file.name, fileExtension)) {
        setFileFormatError(true)
        return
      }
    }
    //a new file is ready
    setFileFormatError(false)
  }

  return (
    <div className="row no-print">
      {!noupload ? <WavFileUploader /> : null}
      {!questionMode ? (
        <div className="col-xs-12">
          {!happyInQuestion ? (
            <button
              disabled={recording || disableRecord}
              className="btn btn-sm record-btn"
              onClick={startRecording}
            >
              {buttonLabel}
            </button>
          ) : null}
          <a
            className={`btn btn-sm neutral-btn ${
              !happyInQuestion ? "hidden" : ""
            }`}
            ref={aRef}
          >
            {lang_config.recorder.button_download}
          </a>
          {happyInQuestion ? (
            <>
              <button className="btn btn-sm send-btn m-5" onClick={ImHappy}>
                {lang_config.recorder.button_happy}
              </button>
              <button className="btn btn-sm record-btn" onClick={ImNotHappy}>
                {lang_config.recorder.button_try_again}
              </button>
            </>
          ) : null}
          &nbsp;{" "}
          {!disableRecord && !happyInQuestion
            ? `${remaining} ${lang_config.recorder.remaining}`
            : ""}
          {disableRecord ? (
            <p className="one-time-plot-reminder">
              {lang_config.recorder.success_msg} Your last sample was taken at{" "}
              {lastPoint}.
            </p>
          ) : null}
        </div>
      ) : (
        <Questionnaire
          profileInfo={profileInfo}
          callback={questionnaireCallback}
          lang_config={lang_config.profile}
        />
        // <div className="col-xs-12" id="question-holder">
        //   {lang_config.question_feeling_better}&nbsp;&nbsp;
        //   <button className="btn btn-sm send-btn" onClick={() => analyze("1")}>
        //     {lang_config.button_yes}
        //   </button>
        //   <button
        //     className="btn btn-sm record-btn"
        //     onClick={() => analyze("-1")}
        //   >
        //     {lang_config.button_no}
        //   </button>
        //   <button
        //     className="btn btn-sm neutral-btn"
        //     onClick={() => analyze("0")}
        //   >
        //     {lang_config.button_same}
        //   </button>
        //   <button
        //     className="btn btn-sm neutral-btn"
        //     onClick={() => analyze("-")}
        //   >
        //     {lang_config.button_refuse}
        //   </button>
        // </div>
      )}
    </div>
  )
}
