import React, { useState, useEffect } from "react"

import SubList from "./sublist"

const default_symptoms = {
  chest_pain: 0,
  cough: 0,
  diarrhoea: 0,
  fever: 0,
  loss_speech: 0,
  loss_taste: 0,
  muscle_pain: 0,
  other: 0,
  pneumonia: 0,
  sore_throat: 0,
  tiredness: 0,
}
const default_medications = {
  als: 0,
  alzheimer: 0,
  cancer: 0,
  depression: 0,
  diabetes: 0,
  heart: 0,
  hypertension: 0,
  ms: 0,
  other: 0,
  parkinson: 0,
}
export default ({ callback, profileInfo, lang_config, privacy_policy }) => {
  const [sex, setSex] = useState(profileInfo ? profileInfo.sex : 1)
  const [age, setAge] = useState(profileInfo ? profileInfo.age : "")
  const [positive, setPositive] = useState(
    profileInfo ? profileInfo.positive : 0
  )
  const [negative, setNegative] = useState(
    profileInfo ? profileInfo.negative : 0
  )
  const [not_tested, setNotTested] = useState(
    profileInfo ? profileInfo.not_tested : 0
  )
  const [on_medication, setOnMedication] = useState(
    profileInfo ? profileInfo.on_medication : 0
  )
  const [showing_symptoms, setShowingSymptoms] = useState(
    profileInfo ? profileInfo.showing_symptoms : 0
  )
  const [symptoms, setSymptoms] = useState(
    profileInfo ? profileInfo.symptoms : default_symptoms
  )
  const [medications, setMedications] = useState(
    profileInfo ? profileInfo.medications : default_medications
  )

  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [noInfo, setNoInfo] = useState(false)

  useEffect(() => {
    if (profileInfo) {
      setSex(profileInfo.sex)
      setAge(profileInfo.age)
      setPositive(profileInfo.positive)
      setNegative(profileInfo.negative)
      setNotTested(profileInfo.not_tested)
      setShowingSymptoms(profileInfo.showing_symptoms)
      setOnMedication(profileInfo.on_medication)
      setSymptoms(profileInfo.symptoms)
      setMedications(profileInfo.medications)
    }
  }, [])

  const ageHandler = event => {
    try {
      var reg = /^\d+$/
      if (reg.test(event.target.value) || event.target.value.length === 0) {
        setAge(event.target.value)
      }
    } catch (err) {}
  }

  const noInfoValidate = () => {
    return !positive && !not_tested && !negative
  }

  const saveData = () => {
    if (age.length === 0) {
      alert("Age can't be empty")
      return
    }
    if (parseInt(age) > 120) {
      alert("Enter correct age")
      return
    }
    if (noInfoValidate()) {
      if (!noInfo) {
        setNoInfo(true)
      }
      return
    } else {
      if (noInfo) {
        setNoInfo(false)
      }
    }
    setDisabled(true)
    setLoading(true)
    callback({
      sex,
      age,
      positive,
      not_tested,
      negative,
      on_medication,
      showing_symptoms,
      symptoms,
      medications,
    })
  }

  const cancelEditing = () => {
    callback(null)
  }
  return (
    <div className="profile-holder">
      <p>
        <label htmlFor="sex">{lang_config.sex}:&nbsp;</label>
        <select
          id="sex"
          value={sex}
          onChange={event => setSex(event.target.value)}
        >
          <option value={1}>{lang_config.male}</option>
          <option value={0}>{lang_config.female}</option>
          <option value={2}>{lang_config.other_sex}</option>
        </select>
      </p>
      <p>
        <label htmlFor="age">{lang_config.age}:&nbsp;</label>
        <input
          type="text"
          id="age"
          maxLength={3}
          value={age}
          onChange={ageHandler}
        />
      </p>

      <ul className="main-list">
        <li className="top-li">
          <input
            type="checkbox"
            checked={positive ? "checked" : ""}
            disabled={negative || not_tested}
            onChange={() => setPositive(!positive)}
            id="positive"
          />
          <label
            className={negative || not_tested ? "disabled" : ""}
            htmlFor="positive"
          >
            {lang_config.positive}
          </label>
        </li>
        <li className="top-li">
          <input
            type="checkbox"
            disabled={positive || not_tested}
            checked={negative ? "checked" : ""}
            onChange={() => setNegative(!negative)}
            id="negative"
          />
          <label
            className={positive || not_tested ? "disabled" : ""}
            htmlFor="negative"
          >
            {lang_config.negative}
          </label>
        </li>
        <li className="top-li">
          <input
            type="checkbox"
            disabled={positive || negative}
            checked={not_tested ? "checked" : ""}
            onChange={() => setNotTested(!not_tested)}
            id="not-tested"
          />
          <label
            className={positive || negative ? "disabled" : ""}
            htmlFor="not-tested"
          >
            {lang_config.not_tested}
          </label>
        </li>
        {noInfo && noInfoValidate() ? (
          <li className="top-li" style={{ color: "red" }}>
            {lang_config.no_info}
          </li>
        ) : null}
        <li className="top-li">
          <input
            type="checkbox"
            checked={showing_symptoms ? "checked" : ""}
            onChange={() => setShowingSymptoms(!showing_symptoms)}
            id="showing_symptoms"
          />
          <label htmlFor="showing_symptoms">
            {lang_config.showing_symptoms}
          </label>
          {showing_symptoms ? (
            <SubList
              state={symptoms}
              setStateFunction={setSymptoms}
              arr={lang_config.symptoms}
            />
          ) : null}
        </li>
        <li className="top-li">
          <input
            type="checkbox"
            checked={on_medication ? "checked" : ""}
            onChange={() => setOnMedication(!on_medication)}
            id="on_med"
          />
          <label htmlFor="on_med">{lang_config.on_medication}</label>
          {on_medication ? (
            <SubList
              state={medications}
              setStateFunction={setMedications}
              arr={lang_config.medications}
            />
          ) : null}
        </li>
      </ul>

      <div
        className="instructions"
        dangerouslySetInnerHTML={{
          __html: privacy_policy,
        }}
      />

      <p>
        <br />
        <button
          disabled={disabled}
          onClick={saveData}
          className="btn  btn-sm send-btn"
        >
          {loading ? lang_config.button_processing : lang_config.button_save}
        </button>
        &nbsp;
        <button className="btn btn-sm neutral-btn" onClick={cancelEditing}>
          {lang_config.button_cancel}
        </button>
      </p>
    </div>
  )
}
