import React, { useState, useEffect, useRef } from "react"
import axios from "axios"

const useFileUpload = (sendLabel, fileExtentsion, apiEndpoint) => {
  const [fileFormatError, setFileFormatError] = useState(false)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const resultsRef = useRef([])
  const countRef = useRef(0)
  const [results, setResults] = useState([])
  const [userEmail, setUserEmail] = useState("")

  useEffect(() => {
    if (resultsRef.current.length > 0) {
      setResults(resultsRef.current)
    }
  }, [resultsRef.current])

  const sendFile = () => {
    console.log(userEmail)
    if (files.length === 0) {
      alert("Select a file first")
      return
    }

    setLoading(true)
    countRef.current = 0
    files.forEach((file, _) => {
      const data = new FormData()
      data.append("file", file)
      data.set("filename", file.name)
      data.set("username", userEmail)
      axios
        .post(apiEndpoint, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          resultsRef.current = resultsRef.current.concat(res.data)
          countRef.current++
          //setResult({ ...res.data })
          setLoading(countRef.current !== files.length)
        })
        .catch(error => {
          setLoading(false)
        })
    })
  }

  const changeHandler = event => {
    const selectedFiles = event.target.files
    if (selectedFiles.length === 0) {
      return
    }

    setFiles([...selectedFiles])
    for (let file of selectedFiles) {
      if (!checkFileExtention(file.name, fileExtentsion)) {
        setFileFormatError(true)
        return
      }
    }
    //a new file is ready
    setFileFormatError(false)
  }

  const checkFileExtention = filename => {
    return (
      String(filename).toLowerCase().indexOf(fileExtentsion.toLowerCase()) !==
      -1
    )
  }

  const FileUploader = () => (
    <>
      <div className="col-xs-3 file-uploader-holder">
        <input
          type="file"
          className="form-control-file file-uploader"
          name="file"
          id={`${fileExtentsion.toLowerCase()}-file-uploader`}
          onChange={changeHandler}
          multiple
        />
        <label
          htmlFor={`${fileExtentsion.toLowerCase()}-file-uploader`}
          className="uploader-label"
        >
          Upload {fileExtentsion.toUpperCase()}
        </label>
        <div className="file-names-holder" className="text-left">
          {files.length !== 0
            ? files.map((item, i) => (
                <p key={i} className="file-name">
                  {checkFileExtention(item.name, fileExtentsion) ? (
                    item.name
                  ) : (
                    <span className="danger">{item.name}</span>
                  )}
                </p>
              ))
            : null}
          {fileFormatError ? (
            <>
              <hr />
              <p className="danger file-format-error bold">
                {fileFormatError
                  ? `All file should be in .${fileExtentsion.toUpperCase()} format`
                  : ""}
              </p>
            </>
          ) : null}
        </div>
      </div>
      <div className="col-xs-3">
        <button
          disabled={loading || disabled || fileFormatError}
          className="btn  btn-sm send-btn"
          onClick={sendFile}
        >
          {loading
            ? "Processing..."
            : !loading && disabled
            ? "Already calculated"
            : sendLabel}
        </button>
      </div>
    </>
  )

  return [results, FileUploader, setUserEmail, setResults]
}

export default useFileUpload
