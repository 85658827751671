import React from "react"

const SubList = ({ state, setStateFunction, arr = [] }) => {
  return (
    <ul>
      {arr.map((item, index) => {
        return (
          <li key={item["field"]}>
            <input
              type="checkbox"
              checked={state[item["field"]] ? "checked" : ""}
              onChange={() =>
                setStateFunction({
                  ...state,
                  [item["field"]]: state[item["field"]] ? 0 : 1,
                })
              }
              id={item["field"]}
            />
            <label htmlFor={item["field"]}>{item["label"]}</label>
          </li>
        )
      })}
    </ul>
  )
}

export default SubList
