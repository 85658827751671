import React, { useState, useEffect } from "react"
import SubList from "./sublist"

const default_symptoms = {
  chest_pain: 0,
  cough: 0,
  diarrhoea: 0,
  fever: 0,
  loss_speech: 0,
  loss_taste: 0,
  muscle_pain: 0,
  other: 0,
  pneumonia: 0,
  sore_throat: 0,
  tiredness: 0,
}

export default ({ callback, profileInfo, lang_config }) => {
  const [positive, setPositive] = useState(
    profileInfo ? profileInfo.positive : 0
  )
  const [negative, setNegative] = useState(
    profileInfo ? profileInfo.negative : 0
  )
  const [not_tested, setNotTested] = useState(
    profileInfo ? profileInfo.not_tested : 0
  )
  const [showing_symptoms, setShowingSymptoms] = useState(
    profileInfo ? profileInfo.showing_symptoms : 0
  )
  const [symptoms, setSymptoms] = useState(
    profileInfo ? profileInfo.symptoms : default_symptoms
  )

  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  //   const [noInfo, setNoInfo] = useState(false)

  useEffect(() => {
    if (profileInfo) {
      setPositive(profileInfo.positive)
      setNegative(profileInfo.negative)
      setNotTested(profileInfo.not_tested)
      setShowingSymptoms(profileInfo.showing_symptoms)
      setSymptoms(profileInfo.symptoms)
    }
  }, [])

  useEffect(() => {
    if (profileInfo) {
      setPositive(profileInfo.positive)
      setNegative(profileInfo.negative)
      setNotTested(profileInfo.not_tested)
      setShowingSymptoms(profileInfo.showing_symptoms)
      setSymptoms(profileInfo.symptoms)
    }
  }, [profileInfo])

  const saveData = () => {
    setDisabled(true)
    setLoading(true)
    callback({
      positive,
      not_tested,
      negative,
      showing_symptoms,
      symptoms,
    })
  }

  return (
    <div className="profile-holder col-xs-12">
      <h4>{lang_config.questionnaire_prompt}</h4>
      <br />
      <ul className="main-list">
        <li className="top-li">
          <input
            type="checkbox"
            checked={positive ? "checked" : ""}
            disabled={negative || not_tested}
            onChange={() => setPositive(!positive)}
            id="positive"
          />
          <label
            className={negative || not_tested ? "disabled" : ""}
            htmlFor="positive"
          >
            {lang_config.positive}
          </label>
        </li>
        <li className="top-li">
          <input
            type="checkbox"
            disabled={positive || not_tested}
            checked={negative ? "checked" : ""}
            onChange={() => setNegative(!negative)}
            id="negative"
          />
          <label
            className={positive || not_tested ? "disabled" : ""}
            htmlFor="negative"
          >
            {lang_config.negative}
          </label>
        </li>
        <li className="top-li">
          <input
            type="checkbox"
            disabled={positive || negative}
            checked={not_tested ? "checked" : ""}
            onChange={() => setNotTested(!not_tested)}
            id="not-tested"
          />
          <label
            className={positive || negative ? "disabled" : ""}
            htmlFor="not-tested"
          >
            {lang_config.not_tested}
          </label>
        </li>
        {/* {noInfo && noInfoValidate() ? (
          <li className="top-li" style={{ color: "red" }}>
            {lang_config.no_info}
          </li>
        ) : null} */}
        <li className="top-li">
          <input
            type="checkbox"
            checked={showing_symptoms ? "checked" : ""}
            onChange={() => setShowingSymptoms(!showing_symptoms)}
            id="showing_symptoms"
          />
          <label htmlFor="showing_symptoms">
            {lang_config.showing_symptoms}
          </label>
          {showing_symptoms ? (
            <SubList
              state={symptoms}
              setStateFunction={setSymptoms}
              arr={lang_config.symptoms}
            />
          ) : null}
        </li>
      </ul>
      <p>
        <br />
        <button
          disabled={disabled}
          onClick={saveData}
          className="btn  btn-sm send-btn"
        >
          {loading ? lang_config.button_processing : lang_config.button_send}
        </button>
      </p>
    </div>
  )
}
